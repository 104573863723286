import { PENDING_FILINGS, PENDING_STATUSES } from '@/common/modules/constants'

// all config values defined as a function will be evaluated and passed status and filingName as named parameters
export const pendingFilingsTrackerStepsFactory = () => [
  {
    config: {
      isStatic: true,
      isBlocking: true,
      icon: 'tracker/filing-awaiting-client-information',
      title: 'Awaiting Client Information',
      quip: 'Oops! We hit a roadblock',
      blurb: 'We need some information from you to proceed.',
      description: 'Please fill out the missing information from your client portal for us to proceed with your filing.',
      to: ({ status }) => {
        const requiresVerification = status === PENDING_STATUSES.ORDER_VERIFICATION_REQUIRED

        return requiresVerification ? { name: 'verify-order' } : { name: 'orderItemsRequiringAttention' }
      },
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.APOSTILLE,
      PENDING_FILINGS.ASSUMED_NAME,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_AMENDMENT_REPORT,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
      PENDING_FILINGS.COMPLIANCE,
      PENDING_FILINGS.DISSOLVE_A_COMPANY,
      PENDING_FILINGS.FORM_A_COMPANY,
      PENDING_FILINGS.INITIAL_REPORT,
      PENDING_FILINGS.NO_SSN_TAX_ID,
      PENDING_FILINGS.PUBLICATION,
      PENDING_FILINGS.REGISTER_A_COMPANY,
      PENDING_FILINGS.REINSTATEMENT,
      PENDING_FILINGS.SSN_TAX_ID,
      PENDING_FILINGS.S_CORP,
      PENDING_FILINGS.TAX_ID,
      PENDING_FILINGS.TRADE_NAME,
      PENDING_FILINGS.VEHICLE_FORM_A_COMPANY,
      PENDING_FILINGS.WITHDRAW_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.AWAITING_CLIENT_INPUT,
      PENDING_STATUSES.ORDER_VERIFICATION_REQUIRED,
    ],
  },
  {
    config: {
      isStatic: true,
      isBlocking: false,
      icon: 'tracker/filing-new',
      title: 'New',
      quip: `You're on the right track!`,
      blurb: 'We’ve received your order!',
      description: 'Our team has received your order and will begin processing this ASAP.',
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.APOSTILLE,
      PENDING_FILINGS.ASSUMED_NAME,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_AMENDMENT_REPORT,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
      PENDING_FILINGS.CERTIFICATE_OF_GOOD_STANDING,
      PENDING_FILINGS.CERTIFIED_COPY,
      PENDING_FILINGS.COMPLIANCE,
      PENDING_FILINGS.DISSOLVE_A_COMPANY,
      PENDING_FILINGS.FORM_A_COMPANY,
      PENDING_FILINGS.INITIAL_REPORT,
      PENDING_FILINGS.NO_SSN_TAX_ID,
      PENDING_FILINGS.PUBLICATION,
      PENDING_FILINGS.REGISTER_A_COMPANY,
      PENDING_FILINGS.REINSTATEMENT,
      PENDING_FILINGS.SSN_TAX_ID,
      PENDING_FILINGS.S_CORP,
      PENDING_FILINGS.TAX_ID,
      PENDING_FILINGS.TRADE_NAME,
      PENDING_FILINGS.VEHICLE_FORM_A_COMPANY,
      PENDING_FILINGS.WITHDRAW_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.IN_PROGRESS,
      PENDING_STATUSES.NEW,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: true,
      icon: 'tracker/filing-issue',
      title: 'Name Not Available',
      quip: 'Oops! We hit a roadblock',
      blurb: 'Your name isn’t available with the state.',
      description: 'We tried to process your filing, but the company name you requested is not available with the state. Please check your email for more details from your filer.',
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.ASSUMED_NAME,
      PENDING_FILINGS.FORM_A_COMPANY,
      PENDING_FILINGS.REGISTER_A_COMPANY,
      PENDING_FILINGS.TRADE_NAME,
      PENDING_FILINGS.VEHICLE_FORM_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.NAME_NOT_AVAILABLE,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: false,
      icon: 'tracker/filing-held',
      title: 'Pending State Formation',
      quip: `You're on the right track!`,
      blurb: 'We’re waiting on your additional filings to be filed with the state.',
      description: 'Once the state completes your additional filings, our team will be able to proceed with the next steps.',
    },
    filings: [
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
      PENDING_FILINGS.FREE_INITIAL_REPORT,
      PENDING_FILINGS.INITIAL_REPORT,
      PENDING_FILINGS.NO_SSN_TAX_ID,
      PENDING_FILINGS.PUBLICATION,
      PENDING_FILINGS.SSN_TAX_ID,
      PENDING_FILINGS.S_CORP,
      PENDING_FILINGS.TAX_ID,
    ],
    statuses: [
      PENDING_STATUSES.PENDING_CHANGE_OF_AGENT,
      PENDING_STATUSES.PENDING_STATE_FORMATION,
      PENDING_STATUSES.PENDING_VERIFICATION,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: false,
      icon: 'tracker/filing-held',
      title: 'Pending Tax ID',
      quip: `You're on the right track!`,
      blurb: 'Waiting on TaxID from the IRS.',
      description: 'We’re waiting to receive your FEIN from the IRS before we can proceed.',
    },
    filings: [
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
      PENDING_FILINGS.S_CORP,
    ],
    statuses: [
      PENDING_STATUSES.PENDING_TAX_ID,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: true,
      icon: 'tracker/filing-waiting-on-response',
      title: 'Waiting On Response',
      quip: 'Oops! We hit a roadblock',
      blurb: 'We need some more information from you to proceed.',
      description: 'Your dedicated filer has sent you an email detailing what we need in order to complete your filing.',
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.APOSTILLE,
      PENDING_FILINGS.ASSUMED_NAME,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_AMENDMENT_REPORT,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
      PENDING_FILINGS.DISSOLVE_A_COMPANY,
      PENDING_FILINGS.FORM_A_COMPANY,
      PENDING_FILINGS.INITIAL_REPORT,
      PENDING_FILINGS.NO_SSN_TAX_ID,
      PENDING_FILINGS.PUBLICATION,
      PENDING_FILINGS.REGISTER_A_COMPANY,
      PENDING_FILINGS.REINSTATEMENT,
      PENDING_FILINGS.SSN_TAX_ID,
      PENDING_FILINGS.S_CORP,
      PENDING_FILINGS.TAX_ID,
      PENDING_FILINGS.TRADE_NAME,
      PENDING_FILINGS.VEHICLE_FORM_A_COMPANY,
      PENDING_FILINGS.WITHDRAW_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.WAITING_ON_CC_AUTH,
      PENDING_STATUSES.WAITING_ON_INFORMATION,
      PENDING_STATUSES.WAITING_ON_SIGNATURE,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: true,
      icon: 'tracker/filing-issue',
      title: 'Not Filed in State',
      quip: 'Oops! We hit a roadblock',
      blurb: 'Uh-oh!',
      description: 'Unable to locate company in State records.',
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.ASSUMED_NAME,
      PENDING_FILINGS.CERTIFICATE_OF_GOOD_STANDING,
      PENDING_FILINGS.CERTIFIED_COPY,
      PENDING_FILINGS.CHANGE_OF_AGENT,
      PENDING_FILINGS.COMPLIANCE,
      PENDING_FILINGS.DISSOLVE_A_COMPANY,
      PENDING_FILINGS.PUBLICATION,
      PENDING_FILINGS.TRADE_NAME,
      PENDING_FILINGS.WITHDRAW_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.NOT_FILED_IN_STATE,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: true,
      icon: 'tracker/filing-renewal-filed-by-client',
      title: 'Renewal Filed by Client',
      quip: 'Oops! We hit a roadblock',
      blurb: 'Looks like this has already been filed.',
      description: 'We noticed that the renewal has already been filed for this year by you or someone with the authority to file for your company.',
    },
    filings: [
      PENDING_FILINGS.COMPLIANCE,
    ],
    statuses: [
      PENDING_STATUSES.RENEWAL_FILED_BY_CLIENT,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: true,
      icon: 'tracker/filing-issue',
      title: 'Revoked in State',
      quip: 'Oops! We hit a roadblock',
      blurb: 'Uh-oh.',
      description: 'It appears your company was dissolved or revoked.',
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.CERTIFICATE_OF_GOOD_STANDING,
      PENDING_FILINGS.CERTIFIED_COPY,
      PENDING_FILINGS.CHANGE_OF_AGENT,
      PENDING_FILINGS.COMPLIANCE,
      PENDING_FILINGS.DISSOLVE_A_COMPANY,
      PENDING_FILINGS.WITHDRAW_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.REVOKED_IN_STATE,
    ],
  },
  {
    config: {
      isStatic: true,
      isBlocking: false,
      icon: 'tracker/filing-submitted-for-processing',
      title: 'Submitted for Processing',
      quip: `You're on the right track!`,
      blurb: 'Your filing has been submitted!',
      description: 'Great news! Your filing has been submitted for processing.',
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.APOSTILLE,
      PENDING_FILINGS.ASSUMED_NAME,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_AMENDMENT_REPORT,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
      PENDING_FILINGS.CERTIFICATE_OF_GOOD_STANDING,
      PENDING_FILINGS.CERTIFIED_COPY,
      PENDING_FILINGS.DISSOLVE_A_COMPANY,
      PENDING_FILINGS.FORM_A_COMPANY,
      PENDING_FILINGS.INITIAL_REPORT,
      PENDING_FILINGS.NO_SSN_TAX_ID,
      PENDING_FILINGS.PUBLICATION,
      PENDING_FILINGS.REGISTER_A_COMPANY,
      PENDING_FILINGS.REINSTATEMENT,
      PENDING_FILINGS.SSN_TAX_ID,
      PENDING_FILINGS.S_CORP,
      PENDING_FILINGS.TAX_ID,
      PENDING_FILINGS.TRADE_NAME,
      PENDING_FILINGS.VEHICLE_FORM_A_COMPANY,
      PENDING_FILINGS.WITHDRAW_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.SUBMITTED_TO_STATE,
      PENDING_STATUSES.SUBMITTED_TO_IRS,
      PENDING_STATUSES.SUBMITTED_TO_NEWSPAPER,
    ],
  },
  {
    config: {
      isStatic: true,
      isBlocking: false,
      icon: 'tracker/filing-submitted-for-processing',
      title: 'Submitted to State',
      quip: `You're on the right track!`,
      blurb: 'Your filing has been submitted!',
      description: 'Great news! Your filing has been submitted for processing.',
    },
    filings: [
      PENDING_FILINGS.COMPLIANCE,
    ],
    statuses: [
      PENDING_STATUSES.SUBMITTED_TO_STATE,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: true,
      icon: 'tracker/filing-held',
      title: 'On Hold',
      quip: 'Oops! We hit a roadblock',
      blurb: 'We’re working on your order!',
      description: 'Our team is actively working on your order. We’ll keep you updated and may reach out via email if we need any additional information to move forward. Thank you for your patience!',
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.APOSTILLE,
      PENDING_FILINGS.ASSUMED_NAME,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_AMENDMENT_REPORT,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
      PENDING_FILINGS.COMPLIANCE,
      PENDING_FILINGS.DISSOLVE_A_COMPANY,
      PENDING_FILINGS.FORM_A_COMPANY,
      PENDING_FILINGS.INITIAL_REPORT,
      PENDING_FILINGS.NO_SSN_TAX_ID,
      PENDING_FILINGS.PUBLICATION,
      PENDING_FILINGS.REGISTER_A_COMPANY,
      PENDING_FILINGS.REINSTATEMENT,
      PENDING_FILINGS.SSN_TAX_ID,
      PENDING_FILINGS.S_CORP,
      PENDING_FILINGS.TAX_ID,
      PENDING_FILINGS.TRADE_NAME,
      PENDING_FILINGS.VEHICLE_FORM_A_COMPANY,
      PENDING_FILINGS.WITHDRAW_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.BOI_FILED_BY_CLIENT,
      PENDING_STATUSES.HELD,
      PENDING_STATUSES.NOT_READY_TO_FILE,
      PENDING_STATUSES.UNPAID,
      PENDING_STATUSES.WAITING_ON_CERTIFIED_COPIES,
      PENDING_STATUSES.WAITING_ON_COGS,
    ],
  },
  {
    config: {
      isStatic: true,
      isBlocking: false,
      icon: 'tracker/filing-pending-final-review',
      title: 'Pending Final Review',
      quip: `You're on the right track!`,
      blurb: 'Our team is reviewing your order!',
      description: 'Before we submit your order for processing, our filing team is reviewing your information for completeness and accuracy.',
    },
    filings: [
      PENDING_FILINGS.FORM_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.PENDING_FINAL_REVIEW,
    ],
  },
  {
    config: {
      isStatic: false,
      isBlocking: true,
      icon: 'tracker/filing-issue',
      title: 'Incomplete',
      quip: `Oops! We hit a roadblock`,
      blurb: 'We’re having some issues with your order!',
      description: 'We’re having some issues with processing your order please reach out to us so we can get this resolved.',
    },
    filings: [
      PENDING_FILINGS.AMENDMENT,
      PENDING_FILINGS.APOSTILLE,
      PENDING_FILINGS.ASSUMED_NAME,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_AMENDMENT_REPORT,
      PENDING_FILINGS.BENEFICIAL_OWNERSHIP_INFORMATION_REPORT,
      PENDING_FILINGS.CERTIFICATE_OF_GOOD_STANDING,
      PENDING_FILINGS.CERTIFIED_COPY,
      PENDING_FILINGS.DISSOLVE_A_COMPANY,
      PENDING_FILINGS.FORM_A_COMPANY,
      PENDING_FILINGS.INITIAL_REPORT,
      PENDING_FILINGS.NO_SSN_TAX_ID,
      PENDING_FILINGS.PUBLICATION,
      PENDING_FILINGS.REGISTER_A_COMPANY,
      PENDING_FILINGS.REINSTATEMENT,
      PENDING_FILINGS.SSN_TAX_ID,
      PENDING_FILINGS.S_CORP,
      PENDING_FILINGS.TAX_ID,
      PENDING_FILINGS.TRADE_NAME,
      PENDING_FILINGS.VEHICLE_FORM_A_COMPANY,
      PENDING_FILINGS.WITHDRAW_A_COMPANY,
    ],
    statuses: [
      PENDING_STATUSES.INCOMPLETE,
    ],
  },
  {
    config: {
      isStatic: true,
      isBlocking: false,
      icon: '',
      title: 'Completed',
      quip: ``,
      blurb: '',
      description: '',
    },
    filings: [
      ...Object.values(PENDING_FILINGS),
    ],
    statuses: [],
  },
]

export const PENDING_FILINGS_TRACKER_SUPPORT_MAP = (() => {
  const supportMap = new Map()

  for (const step of pendingFilingsTrackerStepsFactory()) {
    for (const filing of step.filings) {
      for (const status of step.statuses) {
        const key = `${filing}-${status}`
        supportMap.set(key, true)
      }
    }
  }

  return supportMap
})()
